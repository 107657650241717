import React from "react"
import '../../assets/style.css';
import {  Container} from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import ReactReadMoreReadLess from "react-read-more-read-less";

const EcommerceApproach=() => {

    const settings = {
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 20,
        // loop: true,
        noSwiping: false,
        breakpoints: {
          567: {
            centeredSlides: false,
            slidesPerView: 2.5,
            spaceBetween: 30,
            // loop: true
          },
          1200: {
            centeredSlides: false,
            pagination: false,
            spaceBetween: 35,
            slidesPerView: 3.5,
            noSwiping: false,
            noSwipingClass:"swiper-wrapper",
          }
        }
      };

    const te = [
        {title:"Spell&Sell", description:"We have created Spell&Sell with the idea to bring retailers and their brands closer together, to make the shopping experience simpler, more personal, and more convenient.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/e-comm-1.png" },
        {title:"Tailor-made",description:"We develop tailor-made e-commerce platforms with an omni-channel approach to offer a more seamless experience across various channels.",imgUrl: "https://assets.sogody.co.uk/sogody/revamp/e-comm-2.png" }, 
        {title:"Future-proof",description:"As a result of using cutting-edge technologies, we build e-commerce platforms that are future-proof and fully customized stores, with fast checkout and seamless payment processing integrations.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/e-comm-3.png"},       
        {title:"Scaling",description:"The key to scaling your business is to focus on testing and experimentation, finding your core customers, and learning from everything that happens. Having a 360° view of user behavior while they are browsing your site is a key concept to learn from your user behavior.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/e-comm-4.png"}, 
        {title:"All-in-one",description:"All-in-One platforms that allow you to connect directly with your customers, and build authentic relationships that lead to long-term success. Easily manage your products and inventory, while having complete control over shipping rates and taxes.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/e-comm-5.png"},       
    ]
    
    return (
        <div className="our-approach-e-comm">  
            <Container> 
                <div className="te-head">
                    <h1>Our approach</h1> 
                </div>
            <Swiper {...settings}>
            {te.map((t)=>{
                return <SwiperSlide>
                <div className="te-content">
                <div className="sv-desktop">
                        <h2 className="title">{t.title}</h2>
                        <p className="description">{t.description}</p>
                    </div>

                    <div className="sv-mobile">
                        <h2 className="title">{t.title}</h2>
                        <ReactReadMoreReadLess
                            charLimit={120}
                            readMoreText={"Read more"}
                            readLessText={"Hide"}
                        >
                        {t.description}
                        </ReactReadMoreReadLess>
                    </div>
                        <img src={t.imgUrl}></img>
                    </div> 
                    </SwiperSlide>
                })}
                </Swiper>
            </Container>
        </div>
    )
}

export default EcommerceApproach;