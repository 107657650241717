import React from 'react'
import Layout from "../../components/Layout"
import Updates from '../../components/Updates'
import CaseStudy from '../../components/CaseStudy'
import FAQWAD from '../../components/FAQWAD'
import WWOWAD from '../../components/WWOWAD'
import WADBoxUnder from '../../components/WADBoxUnder'
import BannerWAD from '../../components/BannerWAD'
import { Seo } from '../../components/Seo'
// import myImg from '../../public/e-commerce-og.png'
import SpellAndSell from '../../components/SpellAndSell'
import EcommerceApproach from '../../components/our-approach/EcommerceApproach'
import { useLocation } from "@reach/router"

export default function() {
    const pathLocation = useLocation();
    const url = pathLocation.href;
    return (
    <Layout>
        {/* image={'https://sogody.com'+myImg} */}
        <Seo 
            title="E-commerce - Sogody" 
            description="We create outstanding online stores, by delivering pixel perfect websites with complete e-commerce capabilities that put your brand at the top of the game with compelling customer journeys."  
            url={url}
        />
        <div className="web-development-page">  
        <BannerWAD/>
        {/* <WADBoxUnder/> */}
        {/* <WADBox/> */}
        <WWOWAD/>
        <EcommerceApproach/>
        <SpellAndSell/>
        <CaseStudy/>
        {/* <FAQWAD/> */}
        <Updates/>
        </div>
    </Layout>
    )
}


