import React from "react"
import '../assets/style.css';
import { Row, Col, Container, Button,} from 'react-bootstrap';

import spell from '../assets/images/services/spell-logo.svg';
import spellBanner from '../assets/images/services/spell-sell-banner.png'
export default function() {

    return (
        <div className="spell" > 
         <Container>  
             <div className="content-box">
                <Row>
                    <Col xs={12} md={12} lg={6} >
                        <div className="spell-content">
                            <img src={spell} className="spell-logo" alt="spell logo"></img>
                            <h1>Create an outstanding online store</h1>
                            <p>As a Shopify expert design and development agency, Spell & Sell provides data-driven, scalable e-commerce to help your online business grow.</p>
                            <Button href="#" className="spell-btn">Coming soon</Button>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="spell-banner">
                        <img src={spellBanner} alt="spell banner"></img>
                    </Col>
                   
                </Row> 
                </div>

               
            </Container> 
        </div>
    )
}



