import React, {useState} from "react"
import { Row, Col, Container,Button} from 'react-bootstrap';
import ContactUs from "./ContactUs";
import banner from '../assets/images/services/e-commerce-banner.png';

import '../assets/style.css';
export default function() {

    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="banner-wad single-service-banner">  
            <Container> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="banner">
                        <h1 className="name">E-commerce</h1>
                        <p className="paragraph">Bringing ideas to life with memorable user interfaces and experiences. Creating fully customized and responsive online stores that speak to your customers and showcase your products.</p>
                        <Button   
                            className="primary-btn-s contact-us-btn"
                            onClick={() => setModalShow(true)} 
                        > Contact us </Button>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="banner-img"><img src={banner} alt="e-commerce banner" /></Col>
                </Row> 
                <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container>
        </div>
    )
}
